* {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* mat-icon {
  height: auto !important;
  width: auto !important;
}

.container-margins {
  @apply px-7 md:px-9 lg:px-11 xl:px-14;
}

html,
body {
  height: 100%;
}
