.btn {
  @apply py-3 px-6 disabled:opacity-50 inline-block cursor-pointer rounded-md focus:outline-none focus:ring-opacity-5 focus:ring-offset-2 focus:ring-2;
}

.btn-danger {
  @apply bg-red-500 text-white hover:bg-red-400 focus:ring-red-500 active:bg-red-600;
}

.btn-primary {
  @apply bg-brand-primary text-white hover:bg-brand-primary-light focus:ring-brand-primary active:bg-brand-primary-dark;
}
